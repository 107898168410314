/*
=========================================================
* SMMM Fatma Çetin Köroğlu - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// SMMM Fatma Çetin Köroğlu components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// SMMM Fatma Çetin Köroğlu examples
// import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Coworking page sections
import Information from "pages/LandingPages/SmmmDashboard/sections/Information";
import Testimonials from "pages/LandingPages/SmmmDashboard/sections/Testimonials";

// Routes
// import footerRoutes from "footer.routes";
import routes from "routes";
import MKSnackbar from "components/MKSnackbar";

// Images
import "assets/css/tailwind.css";
import bgImage from "assets/deskwork.mp4";
import MapComponent from "pages/presentation/components/MapComponent";
import ksmmmo from "assets/link/ksmmmo.jpg";
import ysmmmo from "assets/link/ysmmmo.png";
import a from "assets/link/bolusmmo.svg";
import ismmmo from "assets/link/ismmmo.png";
import duzce from "assets/link/duzcesmmmo.svg";
import turmob from "assets/link/turmob.svg";

const textProps = {
  title: "Güvenilir Muhasebe",
  subtitle:
    "Finansal süreçlerinizi düzenlemek ve muhasebe işlemlerinizi sorunsuz bir şekilde yönetmek için doğru yerdesiniz. Hoş geldiniz!",
  buttonColor: "red",
  skew: {
    color: "white",
  },
};
function Coworking() {
  const { title, subtitle } = textProps;
  const [show, setShow] = useState(true);
  const toggleSnackbar = () => setShow(!show);

  return (
    <>
      <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="dark"
        icon="notifications"
        title="Müşterilerimize üstün kalite hizmet vermek için çabalıyoruz."
        content="Bizi bu numaradan arayın 0(380) 523 2922"
        open={show}
        close={toggleSnackbar}
      />
      <DefaultNavbar routes={routes} sticky />
      <div className="p-0 flex items-center overflow-hidden relative bg-black min-h-screen-75">
        <div className="absolute w-full bg-indigo-500 h-full z-0 opacity-20 top-0 left-0"></div>
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
          className="absolute min-w-full max-w-none min-h-full z-0 transform -translate-x-1/2 -translate-y-1/2 top-7/20 left-1/2"
        >
          <source src={bgImage} type="video/mp4" />
        </video>
        <div className="container mx-auto px-4">
          <div className="relative text-center flex flex-wrap -mx-4 z-1">
            <div className="mx-auto px-4 relative w-full lg:w-8/12">
              <h1 className="text-4xl font-semibold leading-tight text-white">{title}</h1>
              <h4 className="mt-2 text-lg text-white opacity-75 mb-8">{subtitle}</h4>
            </div>
          </div>
        </div>
        {/* <div className="w-full absolute text-white bottom-0 z-1">
         <Skew {...skew} />
       </div> */}
      </div>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Testimonials />

        <Container>
          <MKBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="xl"
            my={24}
            p={6}
            sx={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1533563906091-fdfdffc3e3c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80)",
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={8} lg={5}>
                <MKTypography variant="h5" color="white" fontWeight="bold">
                  Daha fazla bilgi için bize ulaşın
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
                <MKBox width="12rem" ml="auto">
                  <MKButton variant="gradient" color="warning" fullWidth sx={{ boxShadow: "none" }}>
                    0(380) 523 2922
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </Card>
      <MKBox py={6}>
        <Container>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={6} md={4} lg={2}>
              <MKButton
                variant="gradient"
                href="http://www.turmob.org.tr/"
                target="_blank"
                fullWidth
                sx={{ boxShadow: "none" }}
              >
                <MKBox component="img" src={turmob} alt="TURMOB" height="100px" opacity={0.9} />
              </MKButton>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKButton
                variant="gradient"
                href="http://www.duzce.smmmo.org.tr/"
                target="_blank"
                fullWidth
                sx={{ boxShadow: "none" }}
              >
                <MKBox component="img" src={duzce} alt="DÜZCE SMMMO" height="100px" opacity={0.9} />
              </MKButton>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKButton
                variant="gradient"
                href="http://www.istanbulsmmmodasi.org.tr/Index.asp"
                target="_blank"
                fullWidth
                sx={{ boxShadow: "none" }}
              >
                <MKBox
                  component="img"
                  src={ismmmo}
                  alt="İSTANBUL SMMMO"
                  height="100px"
                  opacity={0.9}
                />
              </MKButton>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKButton
                variant="gradient"
                href="http://www.bolu.smmmo.org.tr/"
                target="_blank"
                fullWidth
                sx={{ boxShadow: "none" }}
              >
                <MKBox component="img" src={a} alt="BOLU SMMMO" height="100px" opacity={0.9} />
              </MKButton>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKButton
                variant="gradient"
                href="https://yalovasmmmo.org/"
                target="_blank"
                fullWidth
                sx={{ boxShadow: "none" }}
              >
                <MKBox
                  component="img"
                  src={ysmmmo}
                  alt="YALOVA SMMMO"
                  height="100px"
                  opacity={0.9}
                />
              </MKButton>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKButton
                variant="gradient"
                href="https://www.kocaelismmmo.org.tr/v3/Tr/"
                target="_blank"
                fullWidth
                sx={{ boxShadow: "none" }}
              >
                <MKBox
                  component="img"
                  src={ksmmmo}
                  alt="KOCAELİ SMMMO"
                  height="100px"
                  opacity={0.9}
                />
              </MKButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={2} px={1} mt={2}>
        <div style={{ width: "100%", height: "100%", position: "absolute" }} id="contactUsMap2">
          <MapComponent
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRnjaLxY4TgV-NTLi5kauSySiYASSRMV4&callback=console.debug&libraries=maps,marker&v=beta"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </MKBox>

      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Coworking;
