/**
=========================================================
* SMMM Fatma Çetin Köroğlu - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// SMMM Fatma Çetin Köroğlu themes
import theme from "assets/theme";
import SmmmDashboardPage from "layouts/pages/landing-pages/smmm-dashboard";

// SMMM Fatma Çetin Köroğlu routes
// import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  // allRoutes.map((route) => {
  // if (route.collapse) {
  // return getRoutes(route.collapse);
  // }
  //
  // if (route.route) {
  // return <Route exact path={route.route} element={route.component} key={route.key} />;
  // }
  //
  // return null;
  // });
  //
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SmmmDashboardPage />
      {/* <Routes>
        <Route path="/" element={<SmmmDashboardPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes> */}
    </ThemeProvider>
  );
}
