/*
=========================================================
* SMMM Fatma Çetin Köroğlu - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// SMMM Fatma Çetin Köroğlu pages
import SmmmDashboard from "pages/LandingPages/SmmmDashboard";

export default function SmmmDashboardPage() {
  return <SmmmDashboard />;
}
