/*
=========================================================
* SMMM Fatma Çetin Köroğlu - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// SMMM Fatma Çetin Köroğlu components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// SMMM Fatma Çetin Köroğlu examples
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function Information() {
  return (
    <>
      <MKBox component="section" py={{ xs: 6, md: 12 }}>
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={12}>
              <MKTypography variant="h3" my={1}>
                Neler Yapıyoruz
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="apps"
                      title="Yasal defterler tutmak"
                      description="Genel kabul görmüş muhasebe prensipleri ve ilgili mevzuat hükümleri gereğince yasal defterleri tutmak."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="apps"
                      title="Finansal raporlar hazırlamak ve sunmak"
                      description="Ulusal ve uluslararası standartlara uygun olarak finansal raporlar hazırlamak ve sunmak"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="apps"
                      title="Mali Danışmanlık"
                      description="İşletmelere muhasebe, finans, malî mevzuat konularında mali danışmanlık yapmak."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="apps"
                      title="Yasal İşlemler Yapmak"
                      description="Müşterilerimizin çalışanlarının İş ve sosyal güvenlik mevzuatına göre bordrolama hizmeti ile diğer yasal işlemlerini yapmak"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="apps"
                      title="Finansal tablolar hazırlamak"
                      description=""
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard icon="apps" title="Bordrolama hizmeti vermek" description="" />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="apps"
                      title="Muhasebe sistemleri kurmak, geliştirmek"
                      description=""
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <MKBox component="section" py={{ xs: 6, md: 12 }}>
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={5}>
              <MKTypography variant="h3" my={1}>
                Misyonumuz
              </MKTypography>
              <MKTypography variant="h5" my={1}>
                Çözüm odaklı hizmet
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={2}>
                Firmamız, müşterilerimizin muhasebe sistemleri, raporlama, vergisel yaklaşımlar,
                denetim uygulamaları konularında hizmetler sunarak kârlı bir şekilde büyümelerini
                firma misyonu olarak benimsemiştir.
                <br />
                <br />
                Hedefimiz, şirketlere yalnızca hizmet verdiğimiz bir alanda değil, tüm hizmet
                alanlarımıza giren konularda global bir bakışla yaklaşmak ve olası risk ve
                karlılıklarını dikkate alarak stratejik karar alma süreçlerine katkıda bulunmaktır.
                Anlaşılabilirlikten uzak, kullanışsız ve verimsiz hizmetler üretmek yerine
                işletmelerin ihtiyaçlarını doğru analiz ederek pratik, anlaşılabilir ve verimli
                hizmetler üretiyoruz.
                <br />
                <br />
                İş ve sosyal yaşamda bilgi paylaşımı ve iletişim kanallarını en doğru şekilde
                kullanarak güvenilir ve doğru bilgiyi müşterilerimize ulaştırıyoruz.
                <br />
                <br />
                Mali Müşavirlik, tarafsız, ilkeli, müşteri memnuniyetini esas alan, sorunlara çözüm
                oluşturan teknikleri ile çözüm odaklı hizmet anlayışını benimsemiş bulunmaktadır
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
              <Stack>
                <AboutUsOption icon="mediation" content={<>Anında Destek Hizmeti</>} />
                <AboutUsOption icon="mediation" content={<>Alanında Tecrübeli Uzman Kadro</>} />
                <AboutUsOption icon="mediation" content={<>En Son Teknoloji</>} />
                <AboutUsOption icon="mediation" content={<>Daha Fazla Alternatif</>} />
                <AboutUsOption icon="mediation" content={<>Müşteri memnuniyeti odaklı çalışma</>} />
                <AboutUsOption icon="mediation" content={<>Sektördeki yenilik takibi</>} />
                <AboutUsOption icon="mediation" content={<>Çalışkan Dinamik Disiplinli</>} />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Information;
