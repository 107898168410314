// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdTLgNjp4ZmiX1u-QB0cBgsYmdleZbQrM",
  authDomain: "smmm-fatma-cetin-koroglu.firebaseapp.com",
  projectId: "smmm-fatma-cetin-koroglu",
  storageBucket: "smmm-fatma-cetin-koroglu.appspot.com",
  messagingSenderId: "782379114162",
  appId: "1:782379114162:web:642f015aca7f8a2a77f3ab",
  measurementId: "G-51QQHVGQ0P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
