/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

function MapComponent() {
  const google = window.google;
  const [selectedCenter, setSelectedCenter] = useState(null);

  return (
    <>
      <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: 40.842506408691406, lng: 31.162799835205078 }}
        defaultOptions={{
          styles: [
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e9e9e9",
                },
                {
                  lightness: 17,
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
                {
                  lightness: 20,
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  lightness: 17,
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  lightness: 29,
                },
                {
                  weight: 0.2,
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  lightness: 18,
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  lightness: 16,
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
                {
                  lightness: 21,
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#dedede",
                },
                {
                  lightness: 21,
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  visibility: "on",
                },
                {
                  color: "#ffffff",
                },
                {
                  lightness: 16,
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  saturation: 36,
                },
                {
                  color: "#333333",
                },
                {
                  lightness: 40,
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [
                {
                  color: "#f2f2f2",
                },
                {
                  lightness: 19,
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#fefefe",
                },
                {
                  lightness: 20,
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#fefefe",
                },
                {
                  lightness: 17,
                },
                {
                  weight: 1.2,
                },
              ],
            },
          ],
          scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
        }}
      >
        <Marker
          position={{ lat: 40.842506408691406, lng: 31.162799835205078 }}
          onClick={() => {
            window.open("https://maps.app.goo.gl/RWMrtj8mMemkngrL9", "_blank", "noreferrer");
            setSelectedCenter({ lat: 40.842506408691406, lng: 31.162799835205078 });
          }}
        >
          <InfoWindow onCloseClick={() => {}}>
            <div>
              <h3>SMMM Fatma Çetin Köroğlu</h3>
              <h5>
                Şerefiye Mahallesi, Cumhuriyet Sk. No:43 D:Kat:1 Daire:1, 81020 Düzce Merkez/Düzce
              </h5>
              <h5>03805232922</h5>
              <a href="https://maps.app.goo.gl/RWMrtj8mMemkngrL9" target="_blank" rel="noreferrer">
                Google Maps aç
              </a>
            </div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    </>
  );
}
export default withScriptjs(withGoogleMap(MapComponent));
